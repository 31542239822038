import React, { Component } from 'react';
import Line from "../components/line";

 class Round extends Component {
  // constructor to set state and bind "this"
    constructor(props) {
      super(props);
      this.state = {
        activeAnimation :false,
        activeText : false,
        background : false
      };
      this.handleClick = this.handleClick.bind(this);
      
    }
    handleClick() {

      setTimeout(function () {
        this.setState(
          {background: true}
        );
      }.bind(this), 7000)
      
      this.setState(prevState => ({
        activeAnimation: !prevState.activeAnimation
      }));

    }

    componentDidMount() {
      this.setState({
        activeText: true
      })
    }
    render() {
        let activeHome;
        let activeFirstStep = (this.state.activeAnimation) ? 'm-backgrounds m-backgrounds--hide' : 'm-backgrounds';
        

        if (this.state.activeText) {
          activeHome = 'm-backgrounds__background';
          if(this.state.activeAnimation) {
            activeHome = 'm-backgrounds__background m-backgrounds__background--open';
          } else {
            activeHome = 'm-backgrounds__background' ;
          }
        } else {
          activeHome = 'm-backgrounds__background--close';
        }


        let activeText = (this.state.activeAnimation) ? 'm-backgrounds__infos m-backgrounds__infos--hide' : 'm-backgrounds__infos';

        let startText = (this.state.activeText) ? 'm-backgrounds__infos__text' : 'm-backgrounds__infos__text m-backgrounds__infos__text--hide';
        let startButtom = (this.state.activeText) ? 'm-backgrounds__infos__bottom' : 'm-backgrounds__infos__bottom m-backgrounds__infos__bottom--hide';
   

        return (
          <div>
            {!this.state.background && (
            <section className= {activeFirstStep}>
            
              <div id="backgrounds" className={activeHome}></div>

              <div className={activeText}>
                  <div className={startText}>
                      <p><span>Si vous êtes arrivé ici,</span></p><p><span>ce n’est pas un hasard</span></p>
                  </div>
                  <div className={startButtom}>

                    <button onClick={this.handleClick} className="m-backgrounds__infos__continue">Continuer</button>
                  </div>
              </div>
            </section>
             )}
            <Line active={this.state.activeAnimation} /> 
          </div>
           
        ); 
    }
}
// export the class so you can call it elsewhere
export default Round;