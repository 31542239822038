import React, { Component } from 'react';


 class Video extends Component {
  // constructor to set state and bind "this"
    constructor(props) {
      super(props);
     
    }

 
    handleModalStatus = () => {
      
        document.body.classList.remove('overflow');
        this.props.modalStatus(false);    
        //this.player.pause();
    }

    handleStopPropagation = (e) => {
        e.stopPropagation();
    
    }
  
    render() {
        
       return (
           <div className="m-modal" onClick={this.handleModalStatus}>
               <div className="m-modal__box" onClick={this.handleStopPropagation}>
               <iframe width="1920" height="1080" src="https://www.youtube.com/embed/xqWvzShFnBY/?autoplay=1&rel=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
               
               </div>
               
           </div>
       )
    }
}

export default Video;