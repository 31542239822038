import React, { Component } from 'react';

import * as Scroll from 'react-scroll';
import { Link, Element , Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

import Video from "../components/video";
import jennifer from "../images/jennifer.png"
import logo from "../images/logo.png"

 class Line extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollPosition :1,
      scrollPath : 0,
      prev : 0,
      width: 0, 
      modalStatus : false,
      contact : false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.moveLine = this.moveLine.bind(this);
  }
  
  handleClick() {
    this.setState(prevState => ({
      activeAnimation: !prevState.activeAnimation
    }));
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth })
  }

  openVideo = () => {
    document.body.classList.add('overflow');
    this.setState({ modalStatus: true })
  }

  closeModalStatus = (status) => {
    this.setState({modalStatus: status});
  }


  componentDidMount() {

    this.prev = window.scrollY;

    let path = document.querySelector('#line');
    let pathLength = path.getTotalLength();


    path.style.strokeDasharray = pathLength + ' ' + pathLength;
    path.style.strokeDashoffset = pathLength;
   
    window.addEventListener('scroll',  e => this.moveLine(e), { passive: true });

    this.handleWindowSizeChange() // Set width
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange)
  }


  
  start =() => {
    let main = document.getElementById("main-content");

    let path = document.querySelector('#line');
    let pathBloc = document.getElementById('m-path');
    pathBloc.classList.add('m-path--hide');
    let pathLength = path.getTotalLength();
    let drawLength;



    this.setState({
      scrollPosition : this.state.scrollPosition
    });
    drawLength = this.state.scrollPosition;

    setTimeout( function() {
      main.classList.remove("overflow");
      path.style.strokeDashoffset = pathLength - drawLength;
    },850)

    scroller.scrollTo('first-block', {
      duration : 2000,
      smooth : true,
      offset : -100,
      delay : 800
    });
  }

  gotoSecondBloc = () => {
    scroller.scrollTo('second-block', {
      duration : 2000,
      smooth : true,
      offset : -100
    });
  }

  gotoThirdBloc = () => {
    scroller.scrollTo('third-block', {
      duration : 2000,
      smooth : true,
      offset : -100
    });
  }

  gotoFourthBloc = () => {
    scroller.scrollTo('fourth-block', {
      duration : 2000,
      smooth : true,
      offset : -100
    });
  }


  moveLine = (e) => {
    const window = e.currentTarget;
    
    let path = document.querySelector('#line');
    let svgBox = path.getBBox();
    let pathLength = path.getTotalLength();
    path.style.strokeDasharray = pathLength + ' ' + pathLength;
    path.style.strokeDashoffset = pathLength;
    let pathHeight = svgBox.height;
    let drawLength;
    let radioSvgWindows = (pathLength / (pathHeight - 168) );


    this.setState({
      scrollPosition : ((window.scrollY) * radioSvgWindows)
    });

    drawLength = this.state.scrollPosition;
    path.style.strokeDashoffset = pathLength - drawLength;
  

    this.prev = window.scrollY;

    const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement; 
    const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight,  html.scrollHeight, html.offsetHeight);
    const windowBottom = windowHeight + window.pageYOffset;
    if (windowBottom >= (docHeight - 200)) {
      this.setState({
        contact : false
      })
    } else {
      this.setState({
        contact : true
      })
    }

  }

 

  
  render() {
    const { width,  modalStatus} = this.state;
  
    let showPaths = ( this.state.scrollPosition > 50 ) ? 'm-path m-path--hide' : 'm-path';
    let showLine = (this.props.active) ? 'vs-section m-line m-line--show overflow' : 'vs-section m-line overflow'
    let mainLine;
    let contact;
    let contact2;

    if (width < 768) {
      mainLine = (
        <svg id="svg" width="734" height="4065" xmlns="http://www.w3.org/2000/svg">
          <path d="M401.442841 1c-3.335268 62.707363 20.345133 109.879177 71.041202 141.515441 76.044104 47.454397 201.437142 64.507039 201.437142 257.028045V920.60034c-3.309662 167.90374-54.190847 265.33364-152.643556 292.28971-147.679062 40.4341-344.754299 17.791-344.754299 294.51651 0 276.7255-142.602676 911.52133 29.263066 893.35529 171.865741-18.16605 230.58686 62.41696 282.699115 191.27087 52.112255 128.8539 108.611051.24544 198.49012 79.82511 89.879069 79.57967 63.396144 771.77953-224.634189 812.93245C174.311109 3525.94321 227.44941 3529.50724 164.117697 3760c-42.221142 153.66184-96.5207907 486.44309-162.898947 998.34375" stroke="#343A40" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round"/>
          <path d="M401.442841 1c-3.335268 62.707363 20.345133 109.879177 71.041202 141.515441 76.044104 47.454397 201.437142 64.507039 201.437142 257.028045V920.60034c-3.309662 167.90374-54.190847 265.33364-152.643556 292.28971-147.679062 40.4341-344.754299 17.791-344.754299 294.51651 0 276.7255-142.602676 911.52133 29.263066 893.35529 171.865741-18.16605 230.58686 62.41696 282.699115 191.27087 52.112255 128.8539 108.611051.24544 198.49012 79.82511 89.879069 79.57967 63.396144 771.77953-224.634189 812.93245C174.311109 3525.94321 227.44941 3529.50724 164.117697 3760c-42.221142 153.66184-96.5207907 486.44309-162.898947 998.34375" stroke="#CD413F" strokeWidth="2" fill="none" fillRule="evenodd" id="line" strokeLinecap="round"/>
        </svg>
      )

      contact = (
        <div className="m-line__mainline__contact">
          <a href="mailto:jr@racinecarree-consulting.com">Prêt ? Me contacter</a>
        </div>
      )
      contact2 = (
        <div className="m-line__mainline__path__contact">
          <a href="mailto:jr@racinecarree-consulting.com" className="m-line__mainline__path__contact__button">Prêt ? Me contacter</a>
        </div>
      )

    } 
    else if (width  >= 768 && width  <= 1280  ) {
      mainLine = (
        <svg id="svg" width="936" height="3743" xmlns="http://www.w3.org/2000/svg">
          <path d="M458.48905 1c-1.893229 107.263021 96.626302 169.946615 295.558594 188.050781C1052.44608 216.207031 961.559362 914.367188 629.48905 1002c-332.070313 87.63281-657.4062501 5.03906-604.9921876 263.03516 52.4140625 257.99609-73.046875 616.875 0 750.80859 73.046875 133.93359 368.7109376 21.39844 514.9374996-61.47656 146.226563-82.875 309.726563 72.18359 328.679688 287.96094 18.953125 215.77734 88.390625 484.82812-75.117188 737.25-109.005208 168.28125-156.229166 422.41666-141.671875 762.40625" stroke="#343A40" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round"/>
          <path d="M458.48905 1c-1.893229 107.263021 96.626302 169.946615 295.558594 188.050781C1052.44608 216.207031 961.559362 914.367188 629.48905 1002c-332.070313 87.63281-657.4062501 5.03906-604.9921876 263.03516 52.4140625 257.99609-73.046875 616.875 0 750.80859 73.046875 133.93359 368.7109376 21.39844 514.9374996-61.47656 146.226563-82.875 309.726563 72.18359 328.679688 287.96094 18.953125 215.77734 88.390625 484.82812-75.117188 737.25-109.005208 168.28125-156.229166 422.41666-141.671875 762.40625" stroke="#CD413F" id="line" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round"/>
      </svg> 
      )
      if(this.state.contact) {
        contact = (
          <div className="m-line__mainline__contact">
            <a href="mailto:jr@racinecarree-consulting.com">Prêt ? Me contacter</a>
          </div>
        )
          contact2 = (
            <div className="m-line__mainline__path__contact">
            <a href="mailto:jr@racinecarree-consulting.com" className="m-line__mainline__path__contact__button">Prêt ? Me contacter</a>
          </div>
        )
      } else {
        contact = (
          <div></div>
        ); 
        contact2 = (
          <div className="m-line__mainline__path__contact m-line__mainline__path__contact--red">
            <a href="mailto:jr@racinecarree-consulting.com" className="m-line__mainline__path__contact__button">Prêt ? Me contacter</a>
          </div>
        )
      }
    }
    
    else {
      mainLine = (
        <svg id="svg" width="1084" height="3604" xmlns="http://www.w3.org/2000/svg">
          <path d="M662.473501 1c18.999239 158.852596 165.360563 122.814612 208.491648 110.735095C914.096234 99.655578 1082.63354 99.655578 1038.95842 313.9786c-43.67512 214.32302 50.60861 469.14971 43.67512 586.28291-6.93349 117.1332-73.20495 419.97891-618.649982 419.97891-545.4450338 0-529.18530348 735.06562-354.757589 875.89575 174.427714 140.83013 531.479097-209.77658 769.864943 119.09005 238.385848 328.86662 238.611248 641.83635-163.903454 673.83326C312.672757 3021.05638 187.294579 3448.70252 207.741492 3604"  stroke="#343A40" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round"/>
          <path d="M662.473501 1c18.999239 158.852596 165.360563 122.814612 208.491648 110.735095C914.096234 99.655578 1082.63354 99.655578 1038.95842 313.9786c-43.67512 214.32302 50.60861 469.14971 43.67512 586.28291-6.93349 117.1332-73.20495 419.97891-618.649982 419.97891-545.4450338 0-529.18530348 735.06562-354.757589 875.89575 174.427714 140.83013 531.479097-209.77658 769.864943 119.09005 238.385848 328.86662 238.611248 641.83635-163.903454 673.83326C312.672757 3021.05638 187.294579 3448.70252 207.741492 3604" id="line" stroke="#CD413F" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round"/>
        </svg>
      )
      if(this.state.contact) {
        contact = (
          <div className="m-line__mainline__contact">
            <a href="mailto:jr@racinecarree-consulting.com">Prêt ? Me contacter</a>
          </div>
        );
        contact2 = (
          <div className="m-line__mainline__path__contact ">
            <a href="mailto:jr@racinecarree-consulting.com" className="m-line__mainline__path__contact__button">Prêt ? Me contacter</a>
          </div>
        )
        
      } else {
        contact = (
          <div></div>
        );
        contact2 = (
          <div className="m-line__mainline__path__contact m-line__mainline__path__contact--red">
            <a href="mailto:jr@racinecarree-consulting.com" className="m-line__mainline__path__contact__button">Prêt ? Me contacter</a>
          </div>
        );
      }
      
      
    }

  
    return (
      <div id="main-content" className= {showLine}>
        <div id="m-path" className={showPaths}>
          <svg width="507" height="263" className="m-path__item m-path__item--topleft" xmlns="http://www.w3.org/2000/svg">
          

            <path d="M1 160.460938c85.46875 90.050781 186.729167 120.067708 303.78125 90.050781C421.833333 220.494792 488.636719 137.324219 505.191406 1" stroke="#343A40" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round"/>
          
          </svg>
          <svg width="829" className="m-path__item m-path__item--left" height="969" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1.01028453C101.375.39309703 180.423177 27.5545554 238.144531 82.4946595c86.582032 82.4101565-33.765625 293.1445315 82.023438 394.8671875 115.789062 101.722656 328 76.403058 417.5 228.267935 59.666666 101.243252 89.5 188.693003 89.5 262.349253" stroke="#343A40" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round"/>
          </svg>
          <svg className="m-path__item m-path__item--topright" width="985" height="407" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1c113.403646 193.21875 274.09375 259.317708 482.070313 198.296875 207.976562-61.020833 374.730468 7.625 500.261718 205.9375" stroke="#343A40" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round"/>
          </svg>
          <svg className="m-path__item m-path__item--right" width="544" height="679" xmlns="http://www.w3.org/2000/svg">
            <path d="M339.49898 677.270245c16.375-179.0625-48.61849-295.533854-194.980469-349.414062C-75.0244575 247.03587-25.5869575 20.7780575 177.182574 3.32493255 312.362261-8.31048412 434.254188 23.7272763 542.858355 99.4382138" stroke="#343A40" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round"/>
          </svg>
          <svg className="m-path__item m-path__item--bottomleft"  width="693" height="1062" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1c146.213542 18.2760417 231.019531 80.8671875 254.417969 187.773438C290.515625 349.132813 183.5 631.667969 485.753906 652.289063c201.502604 13.747395 254.904948 150.002604 160.207031 408.765627" stroke="#343A40" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round"/>
          </svg>
        </div>
  
      <section>
        <div className="m-line__first-text">
          <div className="container">
            <h1 className="m-line__title">
              <p><span>C’est que vous êtes prêt(e) à </span></p>
              <p><span>penser différemment la force et </span></p>
              <p><span>la pérennité de votre entreprise</span></p>
              
            </h1>
            <p className="m-line__text">Et à agir de manière agnostique sur votre marque, pour relever les grands défis d’aujourd’hui et de demain.</p>

            <div className="m-line__bottom">
              <button type="button" onClick = {this.start} className="m-line__bottom__button"><span>L’expertise Identitaire Corporate</span></button>
            </div>
          </div>
        </div>

      <div className="m-line__mainline">
      <div className="m-line__mainline__path">
        <div className="container">
          {mainLine}
          {contact2}
        </div>
        
      </div>
     
      <div  className="m-line__mainline__first"> 
        <div className="container">
          <div name="first-block" className="m-line__mainline__wrapper">

            <p 
              data-sal="slide-up"
              data-sal-duration="1000"
              data-sal-delay="400"
              data-sal-easing="cubic-bezier(0.68, -0.55, 0.265, 1.55)"
              id="firsttext"
              className="m-line__mainline__first__title m-line__subtitle">Tel un champion sportif, une entreprise doit être en bonne santé pour relever de grands défis. Une santé à la fois financière, humaine et <span>identitaire</span>.
              </p>
            <div className="m-line__mainline__first__box">
              <p 
              
              data-sal="slide-up"
              data-sal-duration="1000"
              data-sal-delay="300"
              data-sal-easing="cubic-bezier(0.68, -0.55, 0.265, 1.55)"
              className="m-line__paragraph grey accroche">C’est pourquoi j’ai crée l’<strong>Expertise Identitaire Corporate</strong>: pour arrêter de définir des missions d'entreprise au rythme des turnover, de dessiner des personnalités de marque au gré des tendances de marché, de penser des portefeuilles de marques sans lien, d'afficher des visions corporate apparentées davantage à des objectifs. Car les conséquences économiques sont réelles : problématique de fidélisation, environnement concurrentiel oppressant, désengagement dans les transformations, investissements de survie.</p>
              <p 
              data-sal="slide-up"
              data-sal-duration="1000"
              data-sal-delay="300"
              data-sal-easing="cubic-bezier(0.68, -0.55, 0.265, 1.55)"
              className="m-line__paragraph grey accroche">Jamais les entreprises n’ont autant déployé d’énergie pour convaincre, rassurer et séduire et jamais elles n’ont autant été fragilisées.</p>
              <div className="m-line__mainline__first__bottom">
                <div 
                  data-sal = "fade"
                  data-sal-duration="1000"
                  data-sal-delay="400"
                  data-sal-easing="cubic-bezier(0.68, -0.55, 0.265, 1.55)"
                  className="m-line__video" onClick= {this.openVideo}>
                  <div className="m-line__video__icon"></div>
                  <p className="m-line__video__text">Racine Carrée en vidéo</p>
                </div> 
                <button 
                  data-sal = "fade"
                  data-sal-duration="1000"
                  data-sal-delay="400"
                  data-sal-easing="cubic-bezier(0.68, -0.55, 0.265, 1.55)"
                  className="m-down"  onClick = {this.gotoSecondBloc}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M12 4v16M18 14l-6 6-6-6"/>
                  </svg>
                </button>
              </div>
          </div>
        </div>
      </div>

      <div name="second-block" className="m-line__mainline__second">
        <div className="container">
          <div className="m-line__mainline__wrapper">
            <div className="m-line__mainline__second__box__wrapper">
              <div 
                data-sal="fade"
                data-sal-duration="1000"
                data-sal-delay="300"
                data-sal-easing="cubic-bezier(0.68, -0.55, 0.265, 1.55)"
                className="m-line__mainline__second__box__picture">
                <img src={jennifer} alt="jennifer - Racine Carré Consulting" />
              </div>
              <div className="m-line__mainline__second__box-1">
                <p 
                  data-sal="slide-up"
                  data-sal-duration="1000"
                  data-sal-delay="300"
                  data-sal-easing="cubic-bezier(0.68, -0.55, 0.265, 1.55)"
                  className="m-line__paragraph"
                >Les entreprises créent et innovent en permanence. Parce que la technologie et le marché les y obligent. Espérant trouver un solutionnisme technologique à la crise de sens actuelle.
                </p>
                <p 
                  data-sal="slide-up"
                  data-sal-duration="1000"
                  data-sal-delay="300"
                  data-sal-easing="cubic-bezier(0.68, -0.55, 0.265, 1.55)"
                  className="m-line__paragraph"
                >Les entreprises se réinventent de plus en plus vite, tel un passage des Temps Modernes à des Temps Vertigineux. Des entreprises en réaction permanente (le « time to market ») pour espérer réussir à s’adapter au plus vite et ainsi résister à un effet médiatiquement décrit comme étant générationnel.</p>
                <p 
                data-sal="slide-up"
                data-sal-duration="1000"
                data-sal-delay="300"
                data-sal-easing="cubic-bezier(0.68, -0.55, 0.265, 1.55)"
                className="m-line__paragraph"
                >Comme si cette crise de sens n’était finalement qu’une conséquence d’un monde en pleine digitalisation et d’une génération de plus en plus insatiable. <strong>Réagir, inventer, sans jamais oser réellement se repenser</strong>. 
                </p>
              </div>
            </div>
            
            
            <div className="m-line__mainline__second__box-2">
              <p 
                data-sal="slide-up"
                data-sal-duration="1000"
                data-sal-delay="300"
                data-sal-easing="cubic-bezier(0.68, -0.55, 0.265, 1.55)"
                className="m-line__paragraph big white first"
              >Et si la solution des entreprises, de leur force de marque, de leur pérennité et de leur sérénité était dans la construction de Temps Durables ?
                </p>
              <p 
                data-sal="slide-up"
                data-sal-duration="1000"
                data-sal-delay="300"
                data-sal-easing="cubic-bezier(0.68, -0.55, 0.265, 1.55)"
                className="m-line__paragraph big white"
              >Changer d’ère! Celle qui réunirait les entreprises ayant réussi le pari de la prise de hauteur, équipées de véritables outils de décision et d’autonomie permettant de piloter leurs déploiements opérationnels dans une politique de risque maîtrisée.
              </p>
              <div 
                data-sal = "fade"
                data-sal-duration="1000"
                data-sal-delay="400"
                data-sal-easing="cubic-bezier(0.68, -0.55, 0.265, 1.55)"
                className="m-line__mainline__second__button">
                <button  onClick = {this.gotoThirdBloc} className="m-down">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M12 4v16M18 14l-6 6-6-6"/>
                  </svg>
                </button>
              </div>  
            </div>
          </div>
        </div>
      </div>
      <div className="m-line__mainline__third">
        <div className="container">
          <div className="m-line__mainline__wrapper">
            <div name="third-block" className="m-line__mainline__third__box-1">
              <p 
                data-sal="slide-up"
                data-sal-duration="1000"
                data-sal-delay="300"
                data-sal-easing="cubic-bezier(0.68, -0.55, 0.265, 1.55)"
                className="m-line__paragraph small"
              >C’est ce défi que je propose de relever avec les entreprises d’aujourd’hui pour construire celles de demain.</p>
              <p 
                 data-sal="slide-up"
                 data-sal-duration="1000"
                 data-sal-delay="300"
                 data-sal-easing="cubic-bezier(0.68, -0.55, 0.265, 1.55)"
                className="m-line__paragraph small"
              >Avec une approche transdisciplinaire, au départ d’une empreinte identitaire, combinant les meilleurs aspects de la stratégie, du consulting et de la gestion de marque. </p>
              <p 
                 data-sal="slide-up" 
                 data-sal-duration="1000"
                 data-sal-delay="300"
                 data-sal-easing="cubic-bezier(0.68, -0.55, 0.265, 1.55)"
                className="m-line__paragraph small"
              >« <i>La construction identitaire est un processus hautement dynamique et non figé. Elle est d’ailleurs la seule qui permette le changement et la mutabilité dans la cohésion</i> » disait Paul Ricoeur.</p>
            </div>
            <div className="m-line__mainline__third__box-2">
              <p 
                data-sal="slide-up"
                data-sal-duration="1000"
                data-sal-delay="300"
                data-sal-easing="cubic-bezier(0.68, -0.55, 0.265, 1.55)"
                className="m-line__paragraph big white"
              >C'est pourquoi, faire entrer son entreprise dans une démarche de construction identitaire, c'est agir sur des problématiques aussi bien de marque corporate que de marques employeur, marketing, commerciale, de même qu'agir sur la rentabilité durable des entreprises.</p>

                <div 
                  data-sal="fade"
                  data-sal-duration="1000"
                  data-sal-delay="400"
                  data-sal-easing="cubic-bezier(0.68, -0.55, 0.265, 1.55)"
                
                  className="m-line__mainline__third__button">
                  <button className="m-down" onClick={this.gotoFourthBloc}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <path d="M12 4v16M18 14l-6 6-6-6"/>
                    </svg>
                  </button>
                </div>
            </div>
            
          </div>
        </div>
      </div>

      <div className="m-line__mainline__fourth">
        <div className="container">
          <div className="m-line__mainline__wrapper">
            <div name="fourth-block" className="m-line__mainline__fourth__box">
              <p 
                data-sal="slide-up"
                data-sal-duration="1000"
                data-sal-delay="300"
                data-sal-easing="cubic-bezier(0.68, -0.55, 0.265, 1.55)"
                className="m-line__paragraph big grey"
              >C'est pourquoi, animer la construction identitaire d'une entreprise, c'est également définir progressivement une raison d'être évolutive qui doit, non pas se résumer en une mission qui a du sens, mais se penser comme une véritable philosophie économique ... tel un changement d'ère.</p>
                <div 
                 data-sal="slide-up"
                 data-sal-duration="1000"
                 data-sal-delay="300"
                 data-sal-easing="cubic-bezier(0.68, -0.55, 0.265, 1.55)"
                 className="m-line__mainline__fourth__signature">
                  <svg width="183" height="72" xmlns="http://www.w3.org/2000/svg">
                    <g stroke="#858E96" strokeWidth="2.10000014" fill="none" fillRule="evenodd" strokeLinecap="round">
                      <path d="M92.3500062 20.3480013c-1.1970001-2.1840001-9.5550006-1.1410001-11.9000008 19.1450013-1.9530001 16.8000012 18.9490013 7.8190006 22.2670016 3.9060003 3.318-3.9130003 4.949-5.1100004 6.643-25.5850018 1.694-20.47500135-4.774 21.1050015 0 29.8830021 4.249001 7.8120005 8.400001-20.4750014 9.338001-28.546002.035059-.269626-.117116-.528718-.369679-.6294129-.252562-.1006948-.541255-.0173735-.701321.2024129-3.052 4.1580003-9.996001 14.896001-4.55 20.1880014 6.832 6.6360004 38.283002-.9800001 38.283002-.9800001"/>
                      <path d="M181.138012 22.2100014S6.98500041 55.7120037 1.51800003 68.3120046C-3.94900034 80.9120054 127.616009 28.3000019 138.844009 21.5240014c11.228001-6.7760005 9.968001-12.20800083 9.100001-13.38400091-.868-1.17600008-3.045-7.4200005-37.226003-6.74100046C76.5370051 2.07800007 2.00100007 11.7590007 2.00100007 11.7590007"/>
                    </g>
                  </svg>
                </div>
                <div 
                  data-sal="slide-up"
                  data-sal-duration="1000"
                  data-sal-delay="300"
                  data-sal-easing="cubic-bezier(0.68, -0.55, 0.265, 1.55)"
                  className="m-line__mainline__fourth__infos">
                  <p className="m-line__mainline__fourth__infos__name">Jennifer Rollet de Coninck</p>
                  <p className="m-line__mainline__fourth__infos__job">Fondatrice et gérante de Racine Carrée Consulting</p>
                  <a className="m-line__mainline__fourth__infos__tel" href="tel:0663426863">06 63 42 68 63</a>
                </div>
                <div 
                 data-sal="slide-up"
                 data-sal-duration="1000"
                 data-sal-delay="300"
                 data-sal-easing="cubic-bezier(0.68, -0.55, 0.265, 1.55)"
                 className="m-line__mainline__fourth__logo">
                  <img src={logo} alt="Racine Carré consulting"/>
                </div>
            </div>
            {contact}
          </div>
        </div>
      </div>

      </div>
      </div>
      </section> 
        {modalStatus && (
        <Video modalStatus = {this.closeModalStatus}/>
        )}
       
      </div>

    );
  }
}
export default Line;