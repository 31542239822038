import React, { Component } from 'react';
import Layout from "../components/layout"
import SEO from "../components/seo"
import Round from "../components/round"
import {isIE} from 'react-device-detect';

 //the class you are making your component from
 class IndexPage extends Component {
 
  render() {
    let content;

    if (isIE) {
      content = (
        <div class="m-ie">
          <div class="m-ie__wrapper">
            <p class="m-ie__title">Ce site ne fonctionne pas sur votre navigateur :(</p>
            <p class="m-ie__text">Essayez avec Google Chrome ou Firefox !</p>
          </div>
        </div>
        )
    } else {
      content = (
        <div>
        <Round />  
        </div>
      )
    }

    return (
      <Layout>
      <SEO 
        title="Expertise Identitaire Corporate - Racine Carrée Consulting"
        description ="Tel un champion sportif, une entreprise doit être en bonne santé pour relever de grands défis. Une santé à la fois financière, humaine et identitaire."
      />
      
        {content}
    </Layout>
    );
  }
}
// export the class so you can call it elsewhere
export default IndexPage;
